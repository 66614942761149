<template>
  <section>
    <content-header :title="$t('maintenanceApplicationPortals')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterRequestPortals')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="search(1)"
        />
      </div>

      <separator class="my-4" :text="$t('listOfApplicationPortals')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :text="$t('create')"
            :on-click="() => $router.push({ name: 'reqAppClientCreate' })"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(appClient, index) in reqAppClients.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="appClient"
            @on-delete="openDeleteModal(appClient)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="reqAppClients.count > 0"
        class="my-4"
        :data="reqAppClients"
        :page-size="pageSize"
        @pagination-go-page="search($event)"
        @pagination-rows-per-page="search(pageIndex, $event)"
      />
    </div>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="nameIsNotCorrect"
      :item="reqAppClient"
      label="name"
      name="reqAppClientName"
      @on-confirm="onDelete()"
    />
  </section>
</template>
<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import ContentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import Separator from '@/components/Separator';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getOrganizations as _getOrganizations } from '@/services/OrganizationService';
import {
  searchReqAppClients as _searchReqAppClients,
  reqAppClientDelete as _reqAppClientDelete,
} from '@/services/ReqAppClientService';
import DeleteModal from '@/components/DeleteModal';

export default {
  name: 'ReqAppClientSearch',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    ContentHeader,
    SubHeader,
    Separator,
    DeleteModal,
  },
  data() {
    return {
      filters: {
        organizations: [],
        companies: [],
      },
      selectedFilters: {
        organization: [],
        company: [],
        name: '',
      },
      reqAppClient: null,
      reqAppClients: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showDeleteModal: false,
    };
  },
  mounted() {
    this.getOrganizations();
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'organization',
          label: this.$t('organization'),
          component: 'FilterMultiSelect',
          options: this.filters.organizations,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.organization,
          onChanged: this.onOrganizationChanged,
        },
        {
          rules: '',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4',
          error: this.$t('mustselectcompany'),
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
  },
  methods: {
    async getOrganizations() {
      await _getOrganizations()
        .then((response) => {
          this.filters.organizations = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getCompanies(organization) {
      await _getCompanies(organization)
        .then((response) => {
          this.filters.companies = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onOrganizationChanged(value) {
      this.selectedFilters.organization = [];
      this.selectedFilters.company = [];

      if (value) {
        this.selectedFilters.organization = [value];
        this.getCompanies(this.selectedFilters.organization[0].value);
      }
    },
    onCompanyChanged(value) {
      this.selectedFilters.company = [];

      if (value) {
        this.selectedFilters.company = [value];
      }
    },
    async search(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        organizationId: this.selectedFilters.organization.length
          ? this.selectedFilters.organization[0].value
          : null,
        companyId: this.selectedFilters.company.length
          ? this.selectedFilters.company[0].value
          : null,
        reqAppClientName: this.selectedFilters.name,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _searchReqAppClients(payload)
        .then(({ data }) => {
          this.reqAppClients = data;
          this.noResultsFound = !this.reqAppClients.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    openDeleteModal(data) {
      this.reqAppClient = { ...data };
      this.showDeleteModal = true;
    },
    onDelete() {
      _reqAppClientDelete(this.reqAppClient.reqAppClientId)
        .then(() => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          this.search();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped></style>
