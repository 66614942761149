<template>
  <section>
    <div class="row">
      <div class="col-md-12">
        <div class="main-page-container">
          <div class="row">
            <sub-header :title="$t('filterLayoutDesignConfigurations')" />
          </div>
          <div class="row">
            <div class="col-md-5">
              <base-input v-model="name" label="" />
            </div>
            <div class="col-md-7 text-right">
              <button class="btn btn-primary">
                <i class="far fa-search" />
                {{ $t('filter') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-right">
        <button class="btn btn-outline-primary mt-2" @click="$emit('layout-form', null)">
          <i class="far fa-plus" />
          {{ $t('createLayoutConfiguration') }}
        </button>
      </div>
      <div v-if="noResultsFound" class="col-md-12">
        <CustomNotFound :text="$t('noResultsFound')" />
      </div>
      <div
        v-for="(layout, index) in layouts.data"
        :key="index"
        class="mt-3 col-md-12"
      >
        <LayoutSearchResult
          :value="layout"
          @on-edit="$emit('layout-form', layout.reqAppClientLayoutDesignConfigurationId)"
          @on-delete="openDeleteModal(layout)"
        />
      </div>
      <div class="col-md-12">
        <custom-pagination
          v-if="layouts.count > 0"
          class="my-4"
          :data="layouts"
          :page-size="pageSize"
          @pagination-go-page="search($event)"
          @pagination-rows-per-page="search(pageIndex, $event)"
        />
      </div>
    </div>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="nameIsNotCorrect"
      :item="layoutConfiguration"
      label="name"
      name="reqAppName"
      @on-confirm="onDelete()"
    />
  </section>
</template>
<script>
import {
  searchReqAppClientLayoutDesignConfigurations as _searchReqAppClientLayoutDesignConfigurations,
  reqAppClientLayoutDelete as _reqAppClientLayoutDelete,
} from '@/services/ReqAppClientService';
import SubHeader from '@/components/SubHeader';
import BaseInput from '@/components/BaseInput.vue';
import LayoutSearchResult from './LayoutSearchResult.vue';
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import DeleteModal from '@/components/DeleteModal';

export default {
  name: 'SearchLayoutDesign',
  components: {
    SubHeader,
    BaseInput,
    LayoutSearchResult,
    CustomPagination,
    CustomNotFound,
    DeleteModal
  },
  props: {
    reqAppClientId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      name: null,
      layoutConfiguration: null,
      layouts: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showDeleteModal: false,
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    async search(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        reqAppClientId: this.reqAppClientId,
        reqAppName: this.name || null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _searchReqAppClientLayoutDesignConfigurations(payload)
        .then(({ data }) => {
          this.layouts = data;
          this.noResultsFound = !this.layouts.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    openDeleteModal(data) {
      this.layoutConfiguration = { ...data };
      this.showDeleteModal = true;
    },
    onDelete() {
      _reqAppClientLayoutDelete(
        this.layoutConfiguration.reqAppClientLayoutDesignConfigurationId,
      )
        .then(() => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          this.search();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped></style>
