import { apiTurnos } from '@/core/api/';
import { serialize } from 'object-to-formdata';

export const searchReqAppClients = (payload) =>
  apiTurnos.get('/api/reqappclient/search', { params: { ...payload } });

export const getReqAppClient = (id) => apiTurnos.get(`/api/reqappclient/${id}`);

export const createReqAppClient = (payload) =>
  apiTurnos.post('/api/reqappclient', payload);

export const editReqAppClient = (payload) =>
  apiTurnos.put(`/api/reqappclient`, payload);

export const getReqAppClientLayouts = (id) =>
  apiTurnos.get(`/api/reqappclient/${id}/layouts`);

export const getReqAppClientLayout = (id, layoutId) =>
  apiTurnos.get(`/api/reqappclient/${id}/layout/${layoutId}`);

export const submitReqAppLayout = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true,
  });

  return apiTurnos[
    model.reqAppClientLayoutDesignConfigurationId ? 'put' : 'post'
  ]('/api/reqappclient/layout', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const reqAppClientLayoutDeleteImage = (layoutId, key) =>
  apiTurnos.delete(`/api/reqappclient/${layoutId}/image/delete`, {
    data: { imageKey: key },
  });

export const reqAppClientDelete = (reqAppClientId) =>
  apiTurnos.delete(`/api/reqappclient/${reqAppClientId}`);

export const reqAppClientLayoutDelete = (layoutId) =>
  apiTurnos.delete(`/api/reqappclient/layout/${layoutId}`);

export const reqAppClientAgencies = (id) =>
  apiTurnos.get(`/api/reqappclient/${id}/agencies`);

export const reqAppClientCountries = (id) =>
  apiTurnos.get(`/api/reqappclient/${id}/countries`);

export const searchReqAppClientLayoutDesignConfigurations = (payload) =>
  apiTurnos.get('/api/reqappclient/layout/search', { params: { ...payload } });

export const deleteAgencyByClient = (reqAppClientId, reqAppAgencyId) =>
  apiTurnos.delete(
    `/api/reqappclient/${reqAppClientId}/agencies/${reqAppAgencyId}`,
  );

export const createAgency = (payload) =>
  apiTurnos.post(`/api/reqappclient/${payload.reqAppClientId}/agencies`, payload);

export const updateAgency = (payload) =>
  apiTurnos.put(`/api/reqappclient/${payload.reqAppClientId}/agencies`, payload);

export const deleteAgency = (payload) =>
  apiTurnos.post(`/api/reqappclient/${payload.reqAppClientId}/agencies/delete`, payload);
