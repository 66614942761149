<template>
  <div class="p-4 listview card border-light">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-5 mt-3">
        <div class="cell">
          <label>{{ $t('name') }}</label>
          <span>{{ value.reqAppName || '-' }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-5 mt-3">
        <div class="cell">
          <label>{{ $t('defaultConfiguration') }}</label>
          <span>{{ $t(value.defaultConfiguration ? 'yes' : 'no') }}</span>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-2">
        <action-button :actions="actions" :text="$t('actions')" />
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'LayoutSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    actions() {
      const value = [{
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('on-edit')
        }];
      if (!this.value.defaultConfiguration)
        value.push({
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        })
      return value;
    }
  }
};
</script>

<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>
