<template>
  <validation-observer ref="form" novalidate>
    <div class="row">
      <div class="col-md-6">
        <validation-provider v-slot="{ errors }" rules="required|max:128">
          <base-input
            v-model="model.reqAppAgencyId"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('agencyCode')"
          />
        </validation-provider>
      </div>
      <div class="col-md-6">
        <validation-provider v-slot="{ errors }" rules="required|max:256">
          <base-input
            v-model="model.reqAppAgencyName"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('agencyName')"
          />
        </validation-provider>
      </div>
      <div class="col-md-12 text-right">
        <button class="btn btn-outline-primary mt-sm-4" @click="onSubmit()">
          <i class="far fa-plus-square" /> {{ $t('add') }}
        </button>
      </div>
      <div class="col-md-12 mt-5 px-4">
        <div class="row agencies-list">
          <div v-for="(item, index) in agencies" class="col-md-12 mt-3 " :key="index">
            <div class="row">
              <div class="col-md-12" v-if="editMode !== index">
                <div class="row">
                  <div class="col-md-4">
                    {{ item.id }}
                  </div>
                  <div class="col-md-4">
                    {{ item.name }}
                  </div>
                  <div class="col-md-4 text-right">
                    <button
                      class="mr-2 btn btn-info btn-sm"
                      @click="edit(index)"
                    >
                      <i class="far fa-edit" />
                      {{ $t('edit') }}
                    </button>
                    <button class="btn btn-danger btn-sm" @click="onDelete(index)">
                      <i class="far fa-trash" />
                      {{ $t('delete') }}
                    </button>
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                </div>
              </div>
              <div class="col-md-12" v-if="editMode == index">
                 <div class="row">
                  <div class="col-md-2 pt-2">
                    {{ item.id }}
                  </div>
                  <div class="col-md-6">
                     <base-input
                      v-model="name"
                      label=""
                    />
                  </div>
                  <div class="col-md-4 text-right">
                    <button
                      class="mr-2 btn btn-primary btn-sm"
                      @click="onEdit(index)"
                    >
                      <i class="far fa-edit" />
                      {{ $t('save') }}
                    </button>
                    <button class="btn btn-secondary btn-sm" @click="editMode = null">
                      <i class="far fa-ban" />
                      {{ $t('cancel') }}
                    </button>
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import {
  createAgency as _createAgency,
  deleteAgency as _deleteAgency,
  updateAgency as _updateAgency,
  reqAppClientAgencies as _reqAppClientAgencies,
} from '@/services/ReqAppClientService';

export default {
  name: 'AgencyManager',
  components: {
    BaseInput,
  },
  props: {
    reqAppClientId: {
      type: Number,
      default: () => null,
    }
  },
  data() {
    return {
      model: { reqAppAgencyId: null, reqAppAgencyName: null },
      editMode: null,
      name: null,
      agencies: []
    };
  },
  mounted() {
    _reqAppClientAgencies(this.reqAppClientId)
        .then((response) => {
          this.agencies = response.data
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
          return false;
        });
  },
  methods: {
    edit(index) {
      const agency = this.agencies[index];
      this.name = agency.name;
      this.editMode = index;
    },
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;

      if (

        this.agencies.some(
          (x) =>
            x.reqAppAgencyId?.toUpperCase() ===
            this.model.reqAppAgencyId?.toUpperCase(),
        )
      ) {
        this.ShowToast('Error', `${this.$t('agencyRepeated')}`, 'error');
        return;
      }

      await _createAgency({...this.model, reqAppClientId: this.reqAppClientId}).then(() => {
        this.ShowSuccessSaveToast();
        this.agencies.push({ id: this.model.reqAppAgencyId, name: this.model.reqAppAgencyName });
        this.model = { reqAppAgencyId: null, reqAppAgencyName: null };
        this.$refs.form.reset();
      })
    },
    onDelete(index) {
      const agency = this.agencies[index];
      _deleteAgency({reqAppAgencyId: agency.id, reqAppClientId: this.reqAppClientId}).then(() => {
        this.ShowSuccessToast(this.$t('successfullyDeleted'));``
        this.agencies.splice(index, 1)
      }).catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onEdit(index) {
      const agency = this.agencies[index];
      _updateAgency({reqAppAgencyName: this.name, reqAppAgencyId: agency.id, reqAppClientId: this.reqAppClientId}).then(() => {
        this.ShowSuccessSaveToast();
        agency.name = this.name;
        this.editMode = null;
      })
    }

  },
};
</script>

<style scoped lang="scss">
.agencies-list {
  border: 1px solid $color-label-default !important;
  border-radius: 5px;
}
.btn-actions {
  font: normal normal normal 10px Lato;
  padding: 5px;
}
::v-deep .c-filterMultiSelect {
  margin-bottom: 0;
}
</style>
