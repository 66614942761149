<template>
  <div class="main-page-container">
    <div class="row">
      <sub-header :title="$t('informationApplicationPortals')" />
    </div>
    <validation-observer ref="form" class="row" novalidate tag="section">
      <div class="col-md-6">
        <validation-provider v-slot="{ errors }" rules="required">
          <filter-select
            v-model="value.companyId"
            :clearable="false"
            :error="errors[0]"
            :error-msg="$t('mustSelectOne')"
            fieldtext="text"
            fieldvalue="value"
            :label="$t('company')"
            :options="companies"
          />
        </validation-provider>
      </div>
      <div class="col-md-6">
        <validation-provider v-slot="{ errors }" rules="required|max:128">
          <base-input
            v-model="value.name"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('name')"
          />
        </validation-provider>
      </div>
      <div class="col-md-12 text-right pt-4">
        <button class="btn btn-secondary float-left" @click="$router.push({name: 'reqAppClient'})">
          <i class="far fa-ban" /> {{ $t('cancel') }}
        </button>
        <button
          v-if="value.reqAppClientId && !hideActions"
          class=" btn btn-outline-primary"
          @click="showAgenciesModal = true"
        >
          <i class="far fa-plus-square" /> {{ $t('agencies') }}
        </button>
        <button v-if="!hideActions" class="ml-2  btn  btn-primary" @click="onSubmit()">
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </validation-observer>
    <custom-modal
      v-model="showAgenciesModal"
      :no-close-on-back-drop="true"
      :title="$t('agencyInformation')"
      size="lg"
    >
      <AgencyManager
        v-if="showAgenciesModal"
        class="mb-4"
        :req-app-client-id="value.reqAppClientId"
      />
    </custom-modal>
  </div>
</template>

<script>
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import BaseInput from '@/components/BaseInput.vue';
import {
  createReqAppClient as _createReqAppClient,
  editReqAppClient as _editReqAppClient,
} from '@/services/ReqAppClientService';
import SubHeader from '@/components/SubHeader.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import AgencyManager from './AgencyManager.vue';

export default {
  name: 'Create',
  components: {
    BaseInput,
    SubHeader,
    CustomModal,
    AgencyManager,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      companies: [],
      showAgenciesModal: false,
    };
  },
  async mounted() {
    await _getCompanies()
      .then((response) => {
        this.companies = response.data || [];
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message));
  },
  methods: {
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;
      const payload = { ...this.value, reqAppClientName: this.value.name };
      if (!this.value.reqAppClientId) {
        _createReqAppClient(payload).then((response) => {
          this.ShowSuccessSaveToast();
          this.$router
            .push({
              name: 'reqAppClientEdit',
              params: { id: response.data },
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        });
      } else {
        _editReqAppClient(payload)
          .then(() => {
            this.ShowSuccessSaveToast();
          })
          .catch((error) => this.ShowErrorToast(error.response.data.message));
      }
    },
  },
};
</script>
