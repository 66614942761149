<template>
  <div class="main-page-container">
    <validation-observer ref="form" class="row" novalidate tag="section">
      <div class="col-md-4">
        <validation-provider v-slot="{ errors }" rules="required|max:128">
          <base-input
            v-model="model.reqAppName"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('name')"
          />
        </validation-provider>
      </div>
      <div class="col-md-4">
        <validation-provider>
          <filter-select
            v-model="model.reqAppCountryId"
            fieldtext="name"
            fieldvalue="id"
            :label="$t('defaultCountryCode')"
            :options="countries"
          />
        </validation-provider>
      </div>
      <div class="col-md-4">
        <custom-image-input
          v-model="model.reqAppClientHeaderLogo"
          :auto-delete="false"
          :file="model.reqAppClientHeaderImageFile"
          :label="$t('logo')"
          :max-height="150"
          :max-width="250"
          @clearFile="onDeleteImage()"
        />
      </div>
      <div class="col-md-8">
        <validation-provider>
          <filter-select
            v-model="model.reqAppAgencyId"
            fieldtext="name"
            fieldvalue="id"
            :is-multiple="true"
            :label="$t('agencies')"
            :options="agencies"
          />
        </validation-provider>
      </div>
      <div class="col-sm-12">
        <hr class="w-100" />
      </div>
      <fieldset class="col-md-4">
        <legend class="ml-3">{{ $t('contentTitle') }}</legend>
        <div class="col-md-12">
          <filter-select
            v-model="model.contentTitleFontWeight"
            fieldtext="text"
            fieldvalue="value"
            :label="$t('fontWeight')"
            :options="fontWeightOptions"
            translate
          />
        </div>
        <div class="col-md-12">
          <filter-color
            v-model="model.contentTitleFontColor"
            :label="$t('textColor')"
          />
        </div>
        <div class="col-md-12">
          <filter-numeric
            v-model="model.contentTitleFontSize"
            :clearable="true"
            measurement-unit="pt"
            :min="0"
            :title="$t('size')"
          />
        </div>
      </fieldset>
      <fieldset class="col-md-4">
        <legend class="ml-3">{{ $t('header') }}</legend>
        <div class="col-sm-12">
          <filter-color
            v-model="model.headerBackgroundColor"
            :label="$t('backgroundColor')"
          />
        </div>
        <div class="col-sm-12">
          <filter-color
            v-model="model.headerTextColor"
            :label="$t('textColor')"
          />
        </div>
        <div class="col-sm-12">
          <filter-numeric
            v-model="model.headerTextSize"
            :clearable="true"
            measurement-unit="pt"
            :min="0"
            :title="$t('textSize')"
          />
        </div>
      </fieldset>
      <fieldset class="col-md-4">
        <legend class="ml-3">{{ $t('content') }}</legend>
        <div class="col-md-12">
          <filter-color
            v-model="model.contentPageBackgroundColor"
            :label="$t('pageBackgroundColor')"
          />
        </div>
        <div class="col-md-12">
          <filter-color
            v-model="model.contentBackgroundColor"
            :label="$t('contentBackgroundColor')"
          />
        </div>
      </fieldset>
      <div class="col-sm-12">
        <hr class="w-100" />
      </div>
      <fieldset class="col-md-4">
        <legend class="ml-3">{{ $t('buttons') }}</legend>
        <div class="col-md-12">
          <filter-color
            v-model="model.buttonBackgroundColor"
            :label="$t('backgroundColor')"
          />
        </div>
        <div class="col-md-12">
          <filter-color
            v-model="model.buttonFontColor"
            :label="$t('textColor')"
          />
        </div>
        <div class="col-md-12">
          <filter-numeric
            v-model="model.buttonFontSize"
            :clearable="true"
            measurement-unit="pt"
            :min="0"
            :title="$t('textSize')"
          />
        </div>
      </fieldset>
      <fieldset class="col-md-4">
        <legend class="ml-3">{{ $t('continueButton') }}</legend>
        <div class="col-md-12">
          <filter-color
            v-model="model.buttonContinueBackgroundColor"
            :label="$t('backgroundColor')"
          />
        </div>
        <div class="col-md-12">
          <filter-color
            v-model="model.buttonContinueFontColor"
            :label="$t('textColor')"
          />
        </div>
        <div class="col-md-12">
          <filter-numeric
            v-model="model.buttonContinueFontSize"
            :clearable="true"
            measurement-unit="pt"
            :min="0"
            :title="$t('textSize')"
          />
        </div>
      </fieldset>
      <fieldset class="col-md-4">
        <legend class="ml-3">{{ $t('backButton') }}</legend>
        <div class="col-md-12">
          <filter-color
            v-model="model.buttonBackBackgroundColor"
            :label="$t('backgroundColor')"
          />
        </div>
        <div class="col-md-12">
          <filter-color
            v-model="model.buttonBackFontColor"
            :label="$t('textColor')"
          />
        </div>
        <div class="col-md-12">
          <filter-numeric
            v-model="model.buttonBackFontSize"
            :clearable="true"
            measurement-unit="pt"
            :min="0"
            :title="$t('textSize')"
          />
        </div>
      </fieldset>
      <div class="col-sm-12">
        <hr class="w-100" />
      </div>
      <fieldset class="col-md-4">
        <legend class="ml-3">{{ $t('elements') }}</legend>
        <div class="col-md-12">
          <filter-select
            v-model="model.contentLabelFontWeight"
            fieldtext="text"
            fieldvalue="value"
            :label="$t('contentLabelFontWeight')"
            :options="fontWeightOptions"
            translate
          />
        </div>
        <div class="col-md-12">
          <filter-color
            v-model="model.contentLabelFontColor"
            :label="$t('labelTextColor')"
          />
        </div>
        <div class="col-md-12">
          <filter-numeric
            v-model="model.contentLabelFontSize"
            :clearable="true"
            measurement-unit="pt"
            :min="0"
            :title="$t('labelTextSize')"
          />
        </div>
        <div class="col-md-12">
          <filter-color
            v-model="model.hyperLinkColor"
            :label="$t('hyperLinkColor')"
          />
        </div>
      </fieldset>
      <div class="col-md-12 mt-3">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('cancel')"
          :text="$t('cancel')"
        />

        <b-button
          class="ml-2 float-right"
          variant="primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import FontWeightOptions from '@/constants/FontWeightOptions';
import CustomImageInput from '@/components/CustomImageInput';
import BaseInput from '@/components/BaseInput.vue';
import {
  getReqAppClientLayout as _getReqAppClientLayout,
  submitReqAppLayout as _submitReqAppLayout,
  reqAppClientLayoutDeleteImage as _reqAppClientLayoutDeleteImage,
  reqAppClientAgencies as _reqAppClientAgencies,
  reqAppClientCountries as _reqAppClientCountries,
} from '@/services/ReqAppClientService';

export default {
  name: 'LayoutDesign',
  components: {
    CustomImageInput,
    BaseInput,
  },
  props: {
    reqAppClientId: {
      type: Number,
      default: null,
    },
    layoutConfigurationId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      model: {
        reqAppClientLayoutDesignConfigurationId: null,
        reqAppClientId: null,
        reqAppAgencyId: [],
        reqAppName: null,
        reqAppClientHeaderLogo: null,
        headerBackgroundColor: null,
        headerTextColor: null,
        headerTextSize: null,
        contentPageBackgroundColor: null,
        contentBackgroundColor: null,
        contentTitleFontWeight: null,
        contentTitleFontColor: null,
        contentTitleFontSize: null,
        contentLabelFontWeight: null,
        contentLabelFontColor: null,
        contentLabelFontSize: null,
        buttonBackgroundColor: null,
        buttonFontColor: null,
        buttonFontSize: null,
        buttonContinueBackgroundColor: null,
        buttonContinueFontColor: null,
        buttonContinueFontSize: null,
        buttonBackBackgroundColor: null,
        buttonBackFontColor: null,
        buttonBackFontSize: null,
        hyperLinkColor: null,
        reqAppClientHeaderImageFile: null,
        reqAppCountryId: null,
      },
      showDeleteModal: false,
      fontWeightOptions: FontWeightOptions,
      agencies: [],
      countries: []
    };
  },
  mounted() {
    this.loadAgencies(this.reqAppClientId);
    this.loadCountries(this.reqAppClientId);
    if (this.layoutConfigurationId) {
      this.loadClientLayout();
    }
  },
  methods: {
    loadClientLayout() {
      _getReqAppClientLayout(
        this.reqAppClientId,
        this.layoutConfigurationId,
      )
        .then((response) => {
          this.model = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    loadAgencies(id) {
      _reqAppClientAgencies(id).then(
        (response) => (this.agencies = response.data),
      );
    },
    loadCountries(id) {
      _reqAppClientCountries(id).then(
        (response) => (this.countries = response.data),
      );
    },
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;

      _submitReqAppLayout({
        ...this.model,
        reqAppClientId: this.reqAppClientId,
      })
        .then((response) => {
          this.ShowSuccessSaveToast();

          if (!this.layoutConfigurationId) {
            this.$emit('layout-configuration-id',response.data);
            this.model.reqAppClientLayoutDesignConfigurationId = response.data;
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onDeleteImage() {
      _reqAppClientLayoutDeleteImage(
        this.layoutConfigurationId,
        'reqAppClientHeaderLogo',
      )
        .then(() => {
          this.model.reqAppClientHeaderLogo = null;
          this.model.reqAppClientHeaderImageFile = null;
          this.ShowSuccessSaveToast();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
