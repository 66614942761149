<template>
  <div class="container-fluid mt-5">
    <Create class="mb-5" :value="model" :hide-actions="showLayoutForm" />
    <separator
      v-if="model.reqAppClientId"
      class="my-4"
      :text="$t(separatorText)"
    />
    <SearchLayoutDesign
      v-if="!showLayoutForm && model.reqAppClientId"
      :req-app-client-id="model.reqAppClientId"
      @layout-form="openForm($event)"
    />
    <LayoutDesign
      v-if="showLayoutForm"
      class="mb-4"
      :req-app-client-id="model.reqAppClientId"
      :layout-configuration-id="layoutConfigurationId"
      @layout-configuration-id="layoutConfigurationId = $event"
      @cancel="showLayoutForm = false"
    />
  </div>
</template>
<script>
import Create from './components/Create.vue';
import Separator from '@/components/Separator';
import SearchLayoutDesign from './components/SearchLayoutDesign.vue';
import LayoutDesign from './components/LayoutDesign.vue';
import { getReqAppClient as _getReqAppClient } from '@/services/ReqAppClientService';

export default {
  name: 'ReqAppClientMaster',
  components: {
    Create,
    SearchLayoutDesign,
    Separator,
    LayoutDesign,
  },
  data() {
    return {
      model: {
        reqAppClientId: null,
        companyId: null,
        name: null,
      },
      showLayoutForm: false,
      layoutConfigurationId: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.name === 'reqAppClientEdit') {
          const clientId = Number(val.params.id);
          this.loadDefaultAppClient(clientId);
        } else {
          this.model.reqAppClientId = null;
        }
      },
    },
  },
  computed: {
    separatorText() {
      let value = 'listOfLayoutDesignConfigurations';
      if (this.showLayoutForm)
        value = this.layoutConfigurationId ? 'editLayoutConfiguration' : 'createLayoutConfiguration'
      return value
    }
  },
  methods: {
    loadDefaultAppClient(id) {
      _getReqAppClient(id).then((response) => {
        const value = response.data;
        this.model = {
          reqAppClientId: value.reqAppClientId,
          companyId: value.companyId,
          name: value.reqAppClientName,
        };
      });
    },
    openForm(id) {
      this.showLayoutForm = true;
      this.layoutConfigurationId = id;
    },
  },
};
</script>
